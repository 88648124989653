
import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline.js";
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";



export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const PrivacyPolicy = () => {
  useTitle("About Us - THE RISE COLLECTION")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>


      <div className="app-container" >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: "30px" }}>ABOUT US</h2>




        </div>


        <p style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Dear User,</p>

        <p style={{ fontSize: '20px' }}> At THE RISE COLLECTION, we’re not just a software company—we’re a team of passionate developers dedicated to crafting unique, arcade-style games for players around the world. Our games are designed to challenge, engage, and keep you coming back for more with increasingly difficult levels and endless replay value.
        </p>
        <p style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>A Journey of Innovation</p>
        <p style={{ fontSize: '20px' }}> Founded by Joshua, Mitch and Benjamin in 2014, RISE began as Rise Studios, publishing our first mobile game, Attack of the Circle (AOTC), built using the Game Salad engine. Inspired by the rapid growth of mobile gaming at the time, we set out with a clear vision: "Innovate without losing sight of creativity." Over the last 10 years, we've transformed that vision into a reality, creating games that reflect both our growth as developers and our commitment to pushing the boundaries of what's possible in gaming. </p>
        <p style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>What We Do</p>
        <p style={{ fontSize: '20px' }}>  We specialize in creating games that are both accessible and challenging, available on multiple platforms. The team also has a detailed skill-set in the Software Development field including (Responsive Web Design, Qualtiy Assurance "Automated and Manual", Analytics and more...)</p>
        <p style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Our Commitment</p>
        <p style={{ fontSize: '20px' }}>At THE RISE COLLECTION, we're driven by a passion for software development and a deep love for gaming. As a team, we're commited to creating immersive expericences and continuously innovating to meet new requirments..</p>
        <p style={{ fontSize: '20px' }}>Thank you for supporting us on this exciting journey. We're thrilled to share our passion with you and look forward to brining you even more exiciting projets in the future.</p>
        <p style={{ fontSize: '20px' }}>- Joshua, Founder & Developer</p>
        <a href="https://www.linkedin.com/company/therisecollection/people " style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Meet the team</a>
      </div>
      <br></br>
      <footer className="footer" id='detail-footer'>
        <div className="footer-content">
          <div className="footer-social">
            <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
              <FaInstagramSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>

            <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
              <FaFacebookSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://twitter.com/rwtrcsc">
              <FaSquareXTwitter FaSquareXTwitter style={{
                color: "white",
                height: "35px",
                width: "25px",
                marginRight: "15px",
              }}></FaSquareXTwitter >

            </a>

            <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
              <FaYoutubeSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
              <FaLinkedin
                style={{ color: "white", height: "35px", width: "25px" }}
              />
            </a>
            <br></br>
            <br></br>
            <a href="/" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>HOME</a>
            <br></br>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PrivacyPolicy
