import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const PortfolioList = [
  {
    images: "15",
    category: "ASSET",
    title: "Hello World",
    serve: "/portfolio/hello-world",
    platform: "",
  },
  {
    images: "10",
    category: "BRAND",
    title: "redlightcam",
    serve: "/portfolio/redlightcam",
    platform: "",
  },
  {
    images: "3",
    category: "MOBILE GAME",
    title: "Baaridge",
    serve: "/portfolio/baaridge",
    platform: "",
  },
  {
    images: "4",
    category: "MOBILE GAME",
    title: "BL1NK",
    serve: "/portfolio/bl1nk",
    platform: "",
  },
  {
    images: "1",
    category: "MOBILE GAME",
    title: "Keep On Trucking",
    serve: "/portfolio/keep-on-trucking",
    platform: "",
  },
  {
    images: "8",
    category: "MOBILE GAME",
    title: "Stack 8",
    serve: "/portfolio/stack-8",
    platform: "",
  },
];

export function useTitle(title) {



  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const FullPortfolio = () => {
  const [query, setQuery] = useState('')

  useTitle("Portfolio - THE RISE COLLECTION")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#0b0b0b' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>


      <div className='gridContainer' style={{ padding: "175px 15px 175px 15px" }}>




{PortfolioList.filter((value) => {
  if (query === '') {
    return value
  } else if (value.title.toLowerCase().includes(query.toLowerCase())) {
    return value
  } 

}).map((value, i) => (
  <div >

    <div key={i}>
      <a href={value.serve} style={{textDecoration: 'none'}}>
        <Card style={{backgroundColor: 'black'}}sx={{ minWidth: 345, }}>

          <CardActionArea>
            <CardMedia
              component="img"
              image={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
              alt="Portfolio Images"
              backgroundColor="black"
            />
            <CardContent style={{backgroundColor: 'black'}}>
                <Typography variant="body2" color="red">
                {value.category} {value.platform}
              </Typography>
              <Typography gutterBottom variant="h5" component="div" >
                <a style={{ color: 'white', textDecoration: "none" }} href={value.serve}>{value.title}</a>
              </Typography>
            
            </CardContent>
          </CardActionArea>
        </Card>

      </a>


    </div>

  </div>




))}

</div>
      <br></br>
      <footer className="footer" id='detail-footer'>
        <div className="footer-content">
          <div className="footer-social">
            <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
              <FaInstagramSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>

            <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
              <FaFacebookSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://twitter.com/rwtrcsc">
              <FaSquareXTwitter FaSquareXTwitter style={{
                color: "white",
                height: "35px",
                width: "25px",
                marginRight: "15px",
              }}></FaSquareXTwitter >

            </a>

            <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
              <FaYoutubeSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
              <FaLinkedin
                style={{ color: "white", height: "35px", width: "25px" }}
              />
            </a>
            <br></br>
            <br></br>
            <a href="/" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>HOME</a>
            <br></br>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FullPortfolio
