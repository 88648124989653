import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";



export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const PrivacyPolicy = () => {
  useTitle("Privacy Policy - THE RISE COLLECTION")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>


      <div className="app-container" >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: "30px" }}>PRIVACY POLICY</h2>




        </div>
        <p style={{ fontSize: '20px' }}>Thank you for choosing THE RISE COLLECTION. We prioritize the privacy of your data and are committed to ensuring the security of your personal information. This privacy policy outlines how we collect, use, and safeguard data across all our software offerings.

          <br></br>
          <p style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Table of Contents:</p>
          <ol style={{ fontSize: '20px' }}>
            <li ><a href="#information-we-collect" style={{ color: 'red' }}>Information We Collect</a></li>
            <li><a href="#how-we-use-your-information" style={{ color: 'red' }}>How We Use Your Information</a></li>
            <li><a href="#data-security" style={{ color: 'red' }}>Data Security</a></li>
            <li><a href="#data-retention" style={{ color: 'red' }}>Data Retention</a></li>
            <li><a href="#your-choices" style={{ color: 'red' }}>Your Choices</a></li>
            <li><a href="#updates-to-privacy-policy" style={{ color: 'red' }}>Updates to Privacy Policy</a></li>
            <li id='information-we-collect'><a href="#contact-information" style={{ color: 'red' }}>Contact Information</a></li>

          </ol>
          <br ></br> Last updated: 6/30/24</p>

        <hr />

        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Information We Collect</h2>
          <p style={{ fontSize: '20px' }}>
            In our software, we only collect essential information necessary for optimal functionality, including: <br></br><br></br>

            - Usernames and email addresses for account creation. <br id='how-we-use-your-information'></br><br></br>

            - Usage data to enhance the performance of our software.
          </p>
          <hr />

        </div>
        <h2 id='data-security' style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>How We Use Your Information
        </h2 >

        <p style={{ fontSize: '20px' }}>
          Your data is used exclusively to provide and improve our software services. We never share your information with third parties for marketing or other purposes.
        </p>
        <hr />

        <h2 style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Data Security</h2>
        <p style={{ fontSize: '20px' }}>
          We employ industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, and destruction, ensuring a secure software experience.
        </p>
        <hr id='data-retention' />
        <h2 style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Data Retention
        </h2>
        <p style={{ fontSize: '20px' }}>
          We retain your data only for the duration necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.
        </p>
        <hr id='your-choices' />
        <h2 style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Your Choices</h2>
        <p style={{ fontSize: '20px' }}>
          You have the right to review, update, or delete your personal information. Please contact us at <a style={{ color: 'red' }} href='mailto:contact@therisecollection.co'>contact@therisecollection.co </a>       </p>
        <hr id='updates-to-privacy-policy' />
        <h2 style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Updates to Privacy Policy</h2>
        <p style={{ fontSize: '20px' }}>
          This privacy policy may be updated to reflect changes in our software and practices. We encourage you to regularly review this page for any updates.</p>
        <hr id='contact-information' />
        <h2 style={{ fontSize: '25px', color: "red", fontWeight: 'bold' }}>Contact Information</h2>
        <p style={{ fontSize: '20px', paddingBottom: '25px' }}>
          For any questions or concerns regarding this privacy policy, please contact us at <a style={{ color: 'red' }} href='mailto:contact@therisecollection.co'>contact@therisecollection.co </a>       </p>

      </div>
      <br></br>
      <footer className="footer" id='detail-footer'>
        <div className="footer-content">
          <div className="footer-social">
            <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
              <FaInstagramSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>

            <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
              <FaFacebookSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://twitter.com/rwtrcsc">
              <FaSquareXTwitter FaSquareXTwitter style={{
                color: "white",
                height: "35px",
                width: "25px",
                marginRight: "15px",
              }}></FaSquareXTwitter >

            </a>

            <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
              <FaYoutubeSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
              <FaLinkedin
                style={{ color: "white", height: "35px", width: "25px" }}
              />
            </a>
            <br></br>
            <br></br>
            <a href="/" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>HOME</a>
            <br></br>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PrivacyPolicy
