import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
    homeClick,
    underlineMenuAbout,
    underlineMenuContact,
    underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";

const iconImage = require("./test-img/portfolio-15.jpg");
const appleImage = require("./test-img/nifty.png");
const screenShot1 = require("./test-img/helloworld-1.png");
const screenShot2 = require("./test-img/helloworld-2.png");
const screenShot3 = require("./test-img/helloworld-3.png");
const screenShot4 = require("./test-img/helloworld-4.png");

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const HelloWorld = () => {
    useTitle("Hello World - THE RISE COLLECTION")

    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
            <div className="flex-box-header header" id="detail">
                <div className="headerText">
                    <a href="/">
                        <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
                    </a>
                </div>
                <div className="menuItems">
                <Link to="/" onClick={homeClick}>
                        <p id="homeMenuText">HOME</p>
                    </Link>
                    <Link to="/#about-us" onClick={underlineMenuAbout}>
                        <p id="aboutUsMenuText">ABOUT US</p>
                    </Link>
                    <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
                        <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
                    </Link>
                    <Link to="/#contact-us" onClick={underlineMenuContact}>
                        <p id="mediaMenuText">CONTACT US</p>
                    </Link>
                </div>
            </div>


            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                        <h2 style={{ fontSize: "18px" }}>HELLO WORLD</h2>
                        <p style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}>THE RISE COLLECTION</p>

                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px', width: '75px' }}>ASSET</div>

                            <div className='left-tag' style={{ background: 'pink', width: '60px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>NIFTY</div>

                        </div>

                    </div>
                </div>
                <hr />
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Asset</h2>
                    <p style={{ fontSize: '18px' }}>
                    Hello World is an island on the Nifty Island Meta-Verse. Nifty Island is centered around user-generated content (UGC), allowing players to terraform landscapes, construct buildings, and decorate with foliage.
                    </p>
                    <p style={{ fontSize: '18px' }}>
                    Players can build and decorate their own islands, complete quests, and play games with friends. Players can also use their non-fungible tokens (NFTs) as avatars in the game.
                    </p>
                    <p style={{ fontSize: '18px' }}>
                    Nifty Island is a product of a Texas-based company that offers a platform for communities to engage in immersive gaming experiences.
                    </p>
                    <hr />

                </div>
                <h2>Media</h2>

                <div className="screenshot-container-sideways">

          <img src={screenShot1} alt='HELLO_WORLD_SCREENSHOT_1' />
          <img src={screenShot2} alt='HELLO_WORLD_SCREENSHOT_2' />
          <img src={screenShot3} alt='HELLO_WORLD_SCREENSHOT_3' />
          <img src={screenShot4} alt='HELLO_WORLD_SCREENSHOT_3' />


        </div>
                <hr />
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: November 28, 2024</li>
                        <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
                            style={{ color: "red" }}>THE RISE COLLECTION</a></li>                    </ul>
                </div>
                <hr></hr>
                <h2>Download</h2>

                <div className="game-description">

                    <a href='https://niftyis.land/rwtrcsc/hello-world?ref=rwtrcsc'><img src={appleImage} style={{ width: '175px', marginLeft: '-10px' }} alt='HELLO_WORLD_PLAY' /></a>

                </div>
            </div>
            <br></br>
            <footer className="footer" id='detail-footer'>
                <div className="footer-content">
                    <div className="footer-social">
                        <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
                            <FaInstagramSquare
                                style={{
                                    color: "white",
                                    height: "35px",
                                    width: "25px",
                                    marginRight: "15px",
                                }}
                            />
                        </a>

                        <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
                            <FaFacebookSquare
                                style={{
                                    color: "white",
                                    height: "35px",
                                    width: "25px",
                                    marginRight: "15px",
                                }}
                            />
                        </a>
                        <a href="https://twitter.com/rwtrcsc">
                            <FaSquareXTwitter FaSquareXTwitter style={{
                                color: "white",
                                height: "35px",
                                width: "25px",
                                marginRight: "15px",
                            }}></FaSquareXTwitter >

                        </a>

                        <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
                            <FaYoutubeSquare
                                style={{
                                    color: "white",
                                    height: "35px",
                                    width: "25px",
                                    marginRight: "15px",
                                }}
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
                            <FaLinkedin
                                style={{ color: "white", height: "35px", width: "25px" }}
                            />
                        </a>
                        <br></br>
                        <br></br>
                        <a href="/privacy-policy" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>PRIVACY POLICY</a>
                        <br></br>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default HelloWorld
